/* Footer.css */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #ffffff; /* Text color */
    font-size: 1rem; /* Adjust the font size */
 
    bottom: 0;
    width: 100%;
  }
  
/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap');

body , html{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden; /* Prevent horizontal scrollbars */
  background-color: #202020; /* Fallback background color */
  font-family: 'Kanit', sans-serif;
}

.overlay {
  flex: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(28, 28, 28, 0.9); /* Semi-transparent black */
  z-index: -1; /* Ensure overlay is above other content */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* Add grid pattern */
  background-image: 
    repeating-linear-gradient(0deg,  transparent 0.5px, rgba(142, 142, 142, 0.2) 5000px, rgba(142, 142, 142, 0.2) 1000px),
    repeating-linear-gradient(90deg, transparent 0.5px, rgba(142, 142, 142, 0.2) 5000px, rgba(142, 142, 142, 0.2) 1000px);
  background-size: 80px 80px; /* Adjust grid size */
}

.App {
  position: relative;
  z-index: 1;
}

.top-circle{
  left: 0;
  top: 0;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 170, 255, 0.2), rgba(0, 0, 255, 0) 70%);
  z-index: 1;
  position: absolute;
}

.custom-button {
  background-color: rgb(45, 45, 45);
  color: #ffffff;
  border: 1px solid rgba(0, 170, 255, 0.8);
  padding: 5px 30px;
  border-radius: 10px;
  transition: all 0.1s ease;
  position: relative;
  overflow: hidden;
  font-size: 14px;
  text-decoration: none;
  overflow: hidden;
}

.custom-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  transition: all 0.6s ease;
  transform: rotate(45deg);
  opacity: 0;
  overflow: hidden;
}

.custom-button:hover {
  color: #00aaff;
  border-color: #00aaff;
  overflow: hidden;
}

.custom-button:hover::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  overflow: hidden;
}

.mobile-view{
  display:none;
}


@media (max-width: 1400px) {
  .web-view{
    display:none;
  }
  .mobile-view{
    display:block;
  }
}
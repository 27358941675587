p {
  color: white !important;
}

.top-text {
  line-height: 0.1;
  font-weight: normal;
}

.text-small {
  font-size: 14px;
}

.text-large {
  font-size: 60px;
  font-weight: bold;
  line-height: 1.1;
}

.text-blue {
  color: #2EA6DA;
}

.description {
  font-size: 14px;
}

.top-text-right {
  font-size: 14px;
  text-align: right;
}

.start-left {
  text-align: left;
}

.dog-image {
  width: 80%;
  height: auto;
  z-index: 99;
  /* Ensure balla.png is on top */
  position: relative;
  right: -10%;
}

.Ellipse-image {
  position: absolute;
  top: 15%;
  width: 30%;
  height: auto;
  z-index: 1;
  /* Ensure Ellipse.png is under balla.png */
  right: 0%;
}


.detail-container {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  color: #00aaff;
  font-size: 14px;
}

.detail-item {
  flex: 1;
  text-align: start;
}

.detail-item2 {
  color: #ffffff;
}

.circle {
  position: absolute;
  top: 8%;
  width: 700px;
  height: 700px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 170, 255, 0.2), rgba(0, 0, 255, 0) 70%);
  z-index: 1;
}

@media (max-width: 1400px) {
  .center-text {
    text-align: center;
  }
  .center-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
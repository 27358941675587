/* Links.css */
.links-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    
  }
  
  .link-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    margin: 10px;
    padding: 10px 0px 0px 0px;
  }
  
  .link-icon {
    width: 50px; /* Adjust the icon size */
    height: 50px;
    margin-bottom: 10px;
  }
  
  .link-label {
    font-size: 1rem;
  }

  .a_links{
    text-decoration: none;
  }

@media (max-width: 576px) {
  
    .link-item {
      margin-bottom: 20px;
    }
  }
  
/* StoryPage.css */

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  /* Prevent horizontal scrollbars */
  background-color: #202020;
  /* Fallback background color */
}

.title {
  font-size: 50px;
  font-weight: bold;
  line-height: 1.1;
}

.story-main-image {
  width: 100%;
}

.story-container {
  padding: 20px 40px;
  height: 100%;
  background: rgba(97, 94, 94, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  /* Rounded corners */
  backdrop-filter: blur(10px);
  /* Blur effect */
  -webkit-backdrop-filter: blur(10px);
  /* Blur effect for Safari */
  position: relative;
  overflow: hidden;
}

.narrative-title{
  font-size: 24px;
}

@media (max-width: 1400px) {

  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.1;
    text-align: center;
  }

  p{
    text-align: center;
  }
  
  .center-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .story-container {
    padding: 20px;
    background: rgba(97, 94, 94, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    /* Rounded corners */
    backdrop-filter: blur(10px);
    /* Blur effect */
    -webkit-backdrop-filter: blur(10px);
    /* Blur effect for Safari */
    position: relative;
    overflow: hidden;
    max-height: 90%;
  }
}
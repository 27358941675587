.goals-card {
  background-color: #262626;
  padding: 10px 50px 10px 50px;
  border-radius: 10px;
  border: 1px solid;
  border-image: linear-gradient(
    103.23deg,
    rgba(255, 255, 255, 0.021) 1.41%,
    rgba(255, 255, 255, 0.21) 39.1%,
    rgba(255, 255, 255, 0.0231) 99.46%
  );
  border-image-slice: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4),0 4px 8px rgba(0, 0, 0, 0.4);
}

.goals-title {
  color: #00aaff;
  font-size: 45px;
  font-weight: bold;
}

.goals-text {
  color: #fff;
  font-size: 14px;
  margin: 15px;
}

@media (max-width: 1400px) {
  .center-text {
    text-align: center;
  }
  .center-button{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .goals-card {
    background-color: #262626;
    padding: 10px 5px 10px 20px;
    border-radius: 10px;
    border: 1px solid;
    border-image: linear-gradient(
      103.23deg,
      rgba(255, 255, 255, 0.021) 1.41%,
      rgba(255, 255, 255, 0.21) 39.1%,
      rgba(255, 255, 255, 0.0231) 99.46%
    );
    border-image-slice: 1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4),0 4px 8px rgba(0, 0, 0, 0.4);
  }
}

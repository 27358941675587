.roadmap-container {
  margin-top: 100px;
  padding: 20px;
  border-radius: 10px;
  font-family: 'Kanit', sans-serif;
}

.topic {
  color: #FFFFFF;
  ;
}

.roadmap-row-container {
  margin-bottom: 20px;
  position: relative;
}

.roadmap-row {
  display: flex;
  justify-content: space-between;
}

.roadmap-item {
  text-align: center;
  position: relative;
  flex: 1;
  margin: 0 10px;
}

.roadmap-step-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px;
}

.roadmap-step {
  background-color: #00aaff;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  z-index: 1;
}

.roadmap-content-description-02 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.special-step {
  background-color: white;
  color: #00aaff;
  border: 2px solid #00aaff;
}

.roadmap-line {
  position: absolute;
  height: 3px;
  top: 50%;
  z-index: 0;
}

.line-0 {
  background-color: #00aaff;
  /* Customizable for first row */
  width: calc(100% - -400px);
  margin-left: 850px;
}

.line-1 {
  background-color: #00aaff;
  /* Customizable for second row */
  width: 500%;
  margin-left: 500px;
}

.line-2 {
  background-color: #00aaff;
  /* Customizable for third row */
  width: calc(150% - -350px);
  margin-left: -1800px;
}

.roadmap-content-head {
  color: #00aaff;
  font-size: 24px;
  font-weight: bold;
}

.roadmap-content {
  background-color: rgba(30, 30, 30, 0.9);
  color: white;
  padding: 50px 50px;
  border-radius: 15px;
  height: 180px;
  /* Ensures equal height for all items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid transparent;
  border-image: linear-gradient(103.23deg,
      rgba(255, 255, 255, 0.021) 1.41%,
      rgba(255, 255, 255, 0.21) 39.1%,
      rgba(255, 255, 255, 0.0231) 99.46%) 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.roadmap-content h4 {
  margin-top: 0;
  font-size: 1.2em;
  color: #0066ff;
}

.roadmap-content p {
  margin: 0;
}

.highlight {
  color: #00aaff;
  /* Light blue color */
}

@media (max-width: 1400px) {
  .line-2 {
    background-color: #00aaff;
    width: 100%;
    margin-left: -100%;
  }

  .roadmap-content {
    background-color: rgba(30, 30, 30, 0.9);
    color: white;
    padding: 50px 50px;
    border-radius: 15px;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid transparent;
    border-image: linear-gradient(103.23deg,
        rgba(255, 255, 255, 0.021) 1.41%,
        rgba(255, 255, 255, 0.21) 39.1%,
        rgba(255, 255, 255, 0.0231) 99.46%) 1;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }

  .line-0 {
    background-color: #00aaff;
    /* Customizable for first row */
    width: calc(125%);
    margin-left: 125%;
  }
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.navbar-nav {
  flex-direction: row;
}

.nav-link {
  color: white !important; 
  padding-right: 20px;
  padding-left: 20px;
}

.ml-auto {
  margin-left: auto !important;
}

.nav-link:hover {
  color: #ddd !important; 
}

@media (max-width: 1400px) {
  .centered-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .custom-toggler {
    border: none;
    padding: 0;
    color: white;
    background-color: transparent;
  }
  
  .custom-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  
  .custom-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 0 0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  
  .custom-toggler[aria-expanded="true"] .custom-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 0 0' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' d='M7 7l16 16M7 23L23 7'/%3E%3C/svg%3E");
  }
  
}

